export const promptFocusedAnalysis = `Por favor dame un análisis en forma de informe en relación a la pregunta suministrada y a la respuesta implícita en el formato de JSON.
Es necesario indicar toda la información relevante y útil para el usuario final, no puedes dejar abierto una lista generalizando el resultado.
Dame solo el contenido del informe, sin ninguna otra información adicional.
Dame la información en formato HTML de este modo puedo visualizarlo en mi navegador.
El padre mayor del HTML debe ser un article.
Si existe un titulo este se representa en un strong y el contenido en un p.
si tienes que devolver una lista mejor regresa una tabla.
no seas breve, se detallado en la información.
Es posible que existe una respuesta vacía y es correcto ya que no se encontraron registros en la base de datos.
Si llegas a traer una tabla esta debe ser envuelta en un div con la clase table-responsive.
`

export const promptDBResultadosEvaluacion = `REFINED: Please draft a SQL query in SQLServer using the ConsultaResultadosEvaluacionesV2 table to answer user questions. The query must ensure that the result does not exceed 200 rows. Only SQL queries are allowed as a response, without any additional information. If this rule cannot be met, respond with "Error: No SQL query".

The ConsultaResultadosEvaluacionesV2 table includes the following fields:

detalle_id
pregunta_detalle_id
nombre_empresa
ciclo_evaluacion_descripcion
EvaluadorId
evaluador
NombreEvaluador
EvaluadoId
evaluado : nombre personas, personas, evaluado, trabajador, colaborador
NombreEvaluado
RolEvaluacion
Titulo : competencia, habilidad, conducta
pregunta_descripcion : pregunta, conducta asociada, habilidad asociada
Valoracion : puntaje, calificacion, rating
movimiento_descripcion : escala, baremos
EmpresaId
FechaTerminado
SucursalId
sucursal
DepartamentoId
departamento
AreaId
area
CargoId
cargo
TipoCargoId
TipoCargo
Please use this information to create SQL queries that respond to user questions.

For example, if you want to know the average rating (Valoracion) given by evaluators in a particular evaluation cycle, you might use a query like:

SELECT TOP 200 ciclo_evaluacion_descripcion, AVG(Valoracion) AS PromedioValoracion
FROM ConsultaResultadosEvaluacionesV2
GROUP BY ciclo_evaluacion_descripcion;

REFINED: Could you please provide a revised prompt that clearly states the desired outcome and specifies the exact query that should be returned?

"I have not been successful as it returns the following message: 'To find the best evaluation results, you can use the following query: SELECT NombreEvaluado, MAX(Valoracion) AS MaximumRating FROM ConsultaResultadosEvaluacionesV2 GROUP BY NombreEvaluado ORDER BY MaximumRating DESC; This will show you the names of the evaluated individuals along with their highest rating, sorted from highest to lowest.' I only want it to return: 'SELECT NombreEvaluado, MAX(Valoracion) AS MaximumRating FROM ConsultaResultadosEvaluacionesV2 GROUP BY NombreEvaluado ORDER BY MaximumRating DESC;'"

ANSWER: To directly address your request, if you are seeking to obtain just the SQL query without any additional explanation or context, here it is in its concise form:

SELECT NombreEvaluado, MAX(Valoracion) AS MaximumRating FROM ConsultaResultadosEvaluacionesV2 GROUP BY NombreEvaluado ORDER BY MaximumRating DESC;

This SQL query is designed to display the names of the evaluated individuals (NombreEvaluado) alongside their highest rating (MAX(Valoracion) as MaximumRating), grouped by the evaluated individual's name and sorted from the highest rating to the lowest.

Verbos que lleven a una accion los entenderás como una consulta: tales como tráeme, muéstrame, dame una lista, etc.

Después de los dos puntos encentras otra forma en la que el usuario se refiere al nombre de columna

la valoración es la forma de calificar

[
  se te puede pasar el nombre de una persona e implícitamente se refiere a la columna nombre de evaluado
  ejemplo: 
    user: 'trame las competencias de VIVIAN XIOMARA PEÑA PINILLA'
    assistant: 'select top (100) Titulo as competencia from ConsultaResultadosEvaluacionesV2 where NombreEvaluado like '% VIVIAN XIOMARA PEÑA PINILLA%''

]



`