
import store from "@/store";

export const createAnalytics = async (message, chatCompletion, ChatAssistantAIId) => {
  const TK = localStorage.getItem('tk')
  const persona = JSON.parse(localStorage.getItem("setPersonaAct"))
  
  let headersList = {
    "Accept": "*/*",
    "Authorization": `Bearer ${TK}`,
    "Content-Type": "application/json"
  }

  let bodyContent = JSON.stringify({
    "ChatAssistantAIId": ChatAssistantAIId,
    "PersonaId": persona.id,
    "EmpresaIdByPersona": persona.empresa.id,
    "UserMessage": message.content,
    "AssistantMessage": chatCompletion.choices[0].message.content,
    "TokenPrompt": chatCompletion.usage.prompt_tokens,
    "TokenResponse": chatCompletion.usage.completion_tokens,
  });

  let response = await fetch(`${store.state.hl_url}ChatAssistantAI/createReportAssistantAI`, {
    method: "POST",
    body: bodyContent,
    headers: headersList
  });

  let data = await response.text();
  console.log(data);

}



