import { chatOneShot } from './useChatAI'
import { promptDBResultadosEvaluacion, promptFocusedAnalysis } from './systemPrompt'
import { createAnalytics } from './useChatAnalytics'
// max tokens: 16385. Se debe tener en cuenta este error.
const cleanString = (string) => string.replace(/(\r\n|\n|\r)/gm, " ")

export const chatGetQuery = async (userMessage) => {
  const chatCompletion = await chatOneShot([
    { role: 'system', content: promptDBResultadosEvaluacion },
    userMessage
  ])

  createAnalytics(userMessage, chatCompletion, 'chatGetQueryResultadosEvaluacion')

  const resultGPT = chatCompletion.choices[0].message
  if( resultGPT.content.includes('Error') ) {
    throw new Error(resultGPT.content)
  } else {
    return cleanString(resultGPT.content)
  }
}


export const chatGetAnalysis = async (userMessage) => {
  const chatCompletion = await chatOneShot([
    { role: 'system', content: promptFocusedAnalysis},
    userMessage
  ])
  console.log('chatCompletion', chatCompletion);

  createAnalytics(userMessage, chatCompletion, 'chatGetAnalysisResultadosEvaluacion')
  return chatCompletion.choices[0].message.content
}