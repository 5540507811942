<script>
import { chatGetQuery, chatGetAnalysis } from './useHelexiumChatAI'
import ChatLayout from './ChatLayout'


export default {
  components: {
    ChatLayout
  },
  data() {
    return {
      userMessage: '',
      historyMessages: [],
      waitingMessage: false,
      indexHistoryTool: 0,
      userMessageBackup: ''
    }
  },
  computed: {
    showMessage() {
      return this.historyMessages.filter(message => message.role !== 'system')
    },
    userMessages() {
      return this.historyMessages.filter(message => message.role === 'user')
    },
    empresaId() {
      return this.$store.getters.userLoggedIn.empresa.id
    }
  },
  methods: {
    handleInput(ev) {
      // const auxIndex = this.indexHistoryTool

      if (ev.key === 'Enter') {
        this.sendMessage()
      } else if (ev.key === 'ArrowUp') {
        this.indexHistoryTool += this.indexHistoryTool < this.userMessages.length ? 1 : 0
        // guarda el mensaje actual en un backup. si cambia de 0


      } else if (ev.key === 'ArrowDown') {
        this.indexHistoryTool += this.indexHistoryTool > 0 ? -1 : 0

      } else if (ev.key === 'Escape') {
        this.indexHistoryTool = 0
      }

      // if (auxIndex === 0 && this.indexHistoryTool > 0) {
      //   this.userMessageBackup = this.userMessage
      // } else if (this.indexHistoryTool === 0 && auxIndex > 0) {
      //   this.userMessage = this.userMessageBackup
      // } else {
      //   this.userMessage = this.userMessages[this.userMessages.length - this.indexHistoryTool].content
      // }

    },
    sendMessage() {
      // save in view
      const userMessageObj = { role: 'user', content: this.userMessage }
      this.historyMessages.push(userMessageObj)
      this.scrollSmoothHistory()
      this.userMessage = ''

      console.log(userMessageObj);
      // send to chat
      this.chat(userMessageObj)
    },
    async chat(messages) {
      this.waitingMessage = true
      this.scrollSmoothHistory()

      try {
        const query = await chatGetQuery(messages)
        await this.passQueryToBackend(query)
        console.log(query);
      } catch (error) {
        console.error(error)
      }
      this.waitingMessage = false
    },
    async passQueryToBackend(query) {
      console.log(this.empresaId);
      const response = await this.$store.getters.fetchPost({ path: `ConsultaResultadosEvaluacionesV2/query/${this.empresaId}`, data: { query } })
      const result = await response.json()
      console.log(result) // Respuesta en forma de tablas

      const virtualContent = `pregunta: ${this.historyMessages[this.historyMessages.length - 1].content} \n respuesta: ${JSON.stringify({ result: result.data })}`
      const virtualUserMessage = { role: 'user', content: virtualContent }
      console.log(virtualUserMessage);
      const assistantResponse = await chatGetAnalysis(virtualUserMessage)

      console.log(assistantResponse)
      this.historyMessages.push({
        role: 'assistant',
        content: assistantResponse,
        query,
        resultQuery: result.data
      })
    },


    scrollSmoothHistory() {
      const chat = this.$el.querySelector('#history')
      this.$nextTick(() => {
        chat.scrollTo({
          top: chat.scrollHeight,
          behavior: 'smooth'
        });
      })
    }
  }
}


</script>

<template>
    <ChatLayout 
    v-model="userMessage"
    title="Asistente resultados evaluación"
    :waitingMessage="waitingMessage"
    :historyMessages="historyMessages" 
    @keyup="handleInput" />
</template>

<style scoped>
.chatbot {
  background-color: #050505;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header-chatbot {
  margin-bottom: 1rem;
}

.chatbot .title {
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}

.messages {
  flex: 1;
  overflow: auto;
  padding: 0;
}

.message {
  list-style: none;
  margin: .25rem 0;
  padding: .5rem .25rem;

}


.m-user {
  display: flex;
  flex-direction: row-reverse;

}

.m-assistant {
  display: flex;
  flex-direction: row;
}

.message-text {
  padding: .25rem 1rem;
  border-radius: 0.5rem;
}

.m-user .message-text {
  border: solid 1px rgb(129, 129, 129);
  max-width: 95%;
}

.m-assistant .message-text {
  background-color: #1c1c1c;
  border: solid 1px #050505;
  max-width: 95%;
}

.input {
  border: solid 1px #050505;
  padding: .5rem;
  margin-top: 1rem;
  border-radius: .5rem;
  font-size: 1rem;
  background-color: #1c1c1c;
  color: #fff;
  margin-bottom: .5rem;
}

.header-chatbot {
  display: flex;
  justify-content: space-between;
  align-items: center;

}
</style>
